import axios from 'axios'
import Swal from 'sweetalert2'
import store from '../../store'
import router from '../../router'

export default class PlannerCodeClass{
    constructor(){
        this.planners=[]
    }   
    getplannersCode(aws,obj,apiKey){
        return new Promise(resolve=>{
            axios.post(`${aws}getPlannersCode`,obj,apiKey).then((r) => {
                if(r.data.length > 0){
                    this.planners = r.data.filter(r=>{
                        r.EmployeesData=`${r.EmployeeCode +' - '+r.EmployeeName}`
                        return r;
                    })
                    this.planners.sort((a,b)=>{
                        return a.EmployeeCode - b.EmployeeCode
                    })
                
                    resolve({data:this.planners,loading:false})  
                }else{
                    
                    Swal.fire({
                        icon: "error",
                        title: "No data found!",
                        text: `The user are not registered in any Sales Office.`,
                    });
                    resolve({data:this.planners,loading:false})  
                }
                  
            }).catch(err=>{
                if(err.response.status == 403 || err.response.status == 401){
                    Swal.fire({
                        icon: "info",
                        title: `Token Expired System will forced logout`,
                        showConfirmButton: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          store.commit("STORE_USERINFO", null);
                          store.commit("STORE_SALESOFFICE", null)
                          store.commit("STORE_CHART_URL", null);
                          router.push("/login");
                        }
                      });
                }
                resolve({data:this.planners,loading:false})  
            })
        })
    }
}