import axios from 'axios'
import Swal from 'sweetalert2'
import _ from 'lodash';
import store from '../../store'
import router from '../../router'

export default class SalesOfficeClass{
    constructor(){
        this.SalesOfficeArr=[]
    }
    getSalesOffice(obj,aws,apiKey){
        return new Promise(resolve=>{
            // getSalesOffices
            axios.get(`${aws}getSalesOffices`,apiKey).then(r=>{
            // axios.post(`${aws}getTest`,obj,apiKey).then(r=>{
                axios.post(`${aws}getCadRequestEmployee`,obj,apiKey).then((res2)=>{
                    const filteredData = r.data.filter((itemR) => {
                        return res2.data.some((itemRes2) => itemRes2.EmployeeCode === itemR.EmployeeCode);
                    });
                    if(filteredData.length > 0){
                        this.SalesOfficeArr=[]
                        this.SalesOfficeArr= _.orderBy(filteredData , ['sort'],['asc']) 
                        if(obj.User_Category !='admin' && obj.User_Category !='GC Admin'){
                            if(obj.User_Category =='GC User'){
                                this.SalesOfficeArr=this.SalesOfficeArr.filter(x=>{
                                    if(x.SalesOfficeName.includes('GC User')){
                                        x.SalesOfficeData= `${x.SalesOfficeCode+' - '+x.SalesOfficeName}`
                                        return x
                                    }
                                })
                                resolve({data:this.SalesOfficeArr,loading:false})
                            }else{
                                this.SalesOfficeArr=this.SalesOfficeArr.filter(x=>{
                                    if(!x.SalesOfficeName.includes('GC User')){
                                        x.SalesOfficeData= `${x.SalesOfficeCode+' - '+x.SalesOfficeName}`
                                        return x
                                    }
                                })
                                resolve({data:this.SalesOfficeArr,loading:false})
                            }
                            
    
    
                        }
                        if(obj.User_Category =='GC User'){
                            this.SalesOfficeArr=this.SalesOfficeArr.filter(x=>{
                                if(x.SalesOfficeName.includes('GC User')){
                                    x.SalesOfficeData= `${x.SalesOfficeCode+' - '+x.SalesOfficeName}`
                                    return x
                                }
                            })
                            resolve({data:this.SalesOfficeArr,loading:false})
                        }
                        else{
                            this.SalesOfficeArr=this.SalesOfficeArr.filter(x=>{
                                x.SalesOfficeData= `${x.SalesOfficeCode+' - '+x.SalesOfficeName}`
                                return x
                            })
                            resolve({data:this.SalesOfficeArr,loading:false})
                        }
    
                    }
                    else{
                        Swal.fire({
                            icon: "error",
                            title: "No data found!",
                            text: `The user are not registered in any Sales Office.`,
                        });
                        resolve({data:this.SalesOfficeArr,loading:false})
                    }
                }).catch(err=>{
                    if(err.response.status == 403 || err.response.status == 401){
                        Swal.fire({
                            icon: "info",
                            title: `Token Expired System will forced logout`,
                            showConfirmButton: true,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              store.commit("STORE_USERINFO", null);
                              store.commit("STORE_SALESOFFICE", null)
                              store.commit("STORE_CHART_URL", null);
                              router.push("/login");
                            }
                          });
                    }
                })
                
            }).catch(err=>{
                if(err.response.status == 403 || err.response.status == 401){
                    Swal.fire({
                        icon: "info",
                        title: `Token Expired System will forced logout`,
                        showConfirmButton: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          store.commit("STORE_USERINFO", null);
                          store.commit("STORE_SALESOFFICE", null)
                          store.commit("STORE_CHART_URL", null);
                          router.push("/login");
                        }
                      });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "No data found!",
                        text: `${err.message}`,
                    });
                    window.location.reload();
                    resolve({data:this.SalesOfficeArr,loading:false})
                }
            })
        })
    }
}